import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function Loading({iconSize = "6x", textClassName = "text-xl", spinnerOnly = false}) {
  return (
    <div className="flex flex-col items-center text-gray-700">
      <FontAwesomeIcon icon={faSpinner} size={iconSize} spin />
      {spinnerOnly ||
        <p className={`mt-5 ${textClassName}`}>Loading...</p>
      }
      </div>
  )
}

Loading.propTypes = {
  iconSize: PropTypes.string,
  textClassName: PropTypes.string,
  spinnerOnly: PropTypes.bool
};

export default Loading;
