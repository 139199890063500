import colorTable from 'color-contrast-table';
import { reject, sortBy, last } from 'lodash';

function calendarBgColorStyle(color) {
  if (color) {
    return {backgroundColor: color};
  } else {
    return {};
  }
}

function calendarTextColorStyle(color) {
  if (color) {
    const [ {combinationScores} ] = colorTable([color, "black", "white"]);
    const {value: textColor} = last(sortBy(reject(combinationScores, {score: "fail"}), ['score']));
    return {color: textColor};
  } else {
    return {};
  }
}

function logError(error) {
  if (newrelic) {
    newrelic.noticeError(error);
  } else {
    console.error(error);
  }
}

export {
  calendarBgColorStyle,
  calendarTextColorStyle,
  logError
}
