import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router';
import CalendarApp from '../calendar/app';

const target = document.getElementById('calendar-app');
const root = createRoot(target);
root.render(
  <StrictMode>
    <Router>
      <Routes>
        <Route path="/feeds/:uid.html" element={<CalendarApp />} />
        <Route path="/embed/:uid.html" element={<CalendarApp />} />
      </Routes>
    </Router>
  </StrictMode>
);
